@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* About Us page container */
.about-us-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Content styling */
.about-us-content {
  padding: 40px;
  background-color: #F5F5F5;
  flex-grow: 1; /* Ensures this area takes up remaining space */
}

.about-us-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #004d40;
}

.about-us-content h2 {
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 10px;
  color: #004d40;
}

.about-us-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}

.about-us-content ul {
  list-style: disc;
  margin-left: 40px;
}

.about-us-content ul li {
  margin-bottom: 10px;
  font-size: 18px;
  color: #444;
}

.about-us-content a {
  color: #004d40;
  text-decoration: none;
  font-weight: 500;
}

.about-us-content a:hover {
  text-decoration: underline;
}
