@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

/* Navbar Styles */
.active-home {
    background-color: #00796A !important; /* Same background color as the login button */
    color: #FFFFFF !important; /* White text */
    padding: 10px 20px; /* Same padding as the login button */
    border-radius: 8px; /* Rounded corners */
    font-weight: 600;
}

/* The rest of your CSS remains the same */

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 4px;
    color: #C6F8DF;
    font-family: 'Montserrat', sans-serif;
    position: relative; /* For positioning the burger menu icon */
}

.logo {
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline */
}

.logo-image {
    width: 56px;
    height: 56px;
    margin-right: 14px;
    margin-left: 70px;
}

.logo-text {
    color: #000000; /* Text color */
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2; /* Space between lines */
    text-align: left; /* Align text to the left */
    display: flex;
    flex-direction: column; /* Stack text vertically */
}

.nav-links {
    list-style: none;
    display: flex;
    margin-left: 80px; /* Pushes nav-links to the far right */
    margin-right: auto;
}

.nav-links li {
    padding-left: 24px; /* 24px of space between navigation items */
    font-weight: 700;
}

.nav-links li:last-child {
    margin-right: 0; /* Remove margin from the last item */
}

.nav-links li a {
    color: #00796A;
    text-decoration: none;
    font-size: 16px;
}

.nav-links li a:hover {
    text-decoration: underline;
}

.login-button {
    background-color: #00796A; /* Button color */
    color: #FFFFFF; /* Text color */
    padding: 10px 20px; /* Button padding */
    border: none;
    border-radius: 8px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    font-size: 14px;
    font-weight: 600;
    margin-left: auto; /* Pushes the button to the right end */
    margin-right: 80px;
}

.login-button:hover {
    background-color: #91F1CB; /* Darker shade on hover */
}

/* Burger Menu Icon */
.menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.nav-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your navbar height */
    right: 15px;
    background: linear-gradient(90deg, #00796A, #005057);
    width: 200px; /* Adjust width as needed */
    padding: 10px;
}

.nav-links.active li {
    padding: 10px;
    font-size: 18px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        display: none;
        width: 100%;
        margin-left: 0;
    }

    .menu-icon {
        display: block;
    }
}
