/* Base styles */
.hero {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center items vertically */
  height: 100vh;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}

.curved-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #91F1CB, #00796A, #005057);
  clip-path: polygon(0 0, 90% 0, 0 500%);
  z-index: -1;
}

.hero-content {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between the image and the text */
  width: 100%; /* Full width to use available space */
  position: relative;
  padding: 2rem; /* Add padding around content */
}

.hero-image {
  width: 50%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  max-width: 794px; /* Set a max width */
}

.heading-container {
  max-width: 50%; /* Limit width of text area */
  padding-right: 2rem; /* Add space between text and image */
}

.heading-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem; /* Relative size for better scaling */
  font-weight: 600;
  margin: 0;
}

.heading-container h1:nth-of-type(2) {
  color: #C6F8DF;
  margin-top: 0.5rem; /* Adjust margin to position it correctly */
}

.heading-container h1:nth-of-type(1) {
  color: #ffffff;
}

.heading-container .description {
  font-family: 'Roboto', sans-serif; /* Set the font to Roboto */
  font-size: 2.4rem; /* Font size as specified */
  font-weight: 600; /* Font weight as specified */
  color: #ffffff; /* Match the highlighted text color or adjust */
  margin: 2.4rem 0 1.5rem 0; /* Add margin to position it below the highlighted text */
}

.cta-button {
  background-color: #00696540; /* Button background color */
  color: #C6F8DF; /* Button text color */
  border: none; /* Remove default border */
  padding: 1rem 2rem; /* Add padding */
  font-size: 1.25rem; /* Font size */
  font-weight: 600; /* Font weight */
  border-radius: 0.5rem; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin-top: 1.5rem; /* Add space above button */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.cta-button:hover {
  background-color: #004d40; /* Darker background on hover */
}

/* Responsive styles */
@media (max-width: 1200px) {
  .heading-container h1 {
    font-size: 3rem; /* Smaller font size on medium screens */
  }

  .heading-container .description {
    font-size: 1rem; /* Adjust for medium screens */
  }

  .cta-button {
    font-size: 1rem; /* Adjust font size for medium screens */
  }

  .hero-image {
    width: 40%; /* Adjust width for medium screens */
  }
}

@media (max-width: 900px) {
  .heading-container h1 {
    font-size: 2.5rem; /* Smaller font size on smaller screens */
  }

  .heading-container .description {
    font-size: 0.875rem; /* Adjust for smaller screens */
  }

  .cta-button {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }

  .hero-image {
    width: 35%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 600px) {
  .heading-container h1 {
    font-size: 2rem; /* Even smaller font size on mobile screens */
  }

  .heading-container .description {
    font-size: 0.75rem; /* Adjust further for mobile screens */
  }

  .cta-button {
    font-size: 0.75rem; /* Adjust font size for mobile screens */
    padding: 0.75rem 1.5rem; /* Adjust padding for mobile screens */
  }

  .hero-image {
    width: 100%; /* Full width on mobile screens */
  }
}
