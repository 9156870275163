@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

/* Main page container */
.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, #00796A, #005057); /* Full page gradient */
  padding: 0 20px; /* Add some horizontal padding */
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat to the entire page */
  color: #C6F8DF; /* Default text color */
}

/* Logo container */
.logo-container {
  display: flex;
  flex-direction: column; /* Stack logo and text vertically */
  align-items: center; /* Center items horizontally */
  margin-right: 50px; /* Space between logo and form */
  text-decoration: none; /* Remove underline from link */
}

.logo-container img {
  width: 150px; /* Adjust the logo size */
  height: auto;
  margin-bottom: 10px; /* Space between logo and text */
}

.logo-container h2 {
  margin: 0; /* Remove default margin */
  font-size: 24px; /* Adjust font size if needed */
  color: #C6F8DF; /* Ensure text color matches the theme */
}

/* Login form container */
.login-container {
  max-width: 400px;
  background: linear-gradient(90deg, #2CAE94, #006965); /* Apply gradient */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure full width on smaller screens */
}

/* Login form styles */
.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container form div {
  margin-bottom: 20px;
}

.login-container form label {
  margin-bottom: 5px;
}

.login-container form input {
  padding: 12px; /* Increase padding for better touch targets */
  font-size: 16px;
  width: 100%; /* Full width */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-container button {
  padding: 12px; /* Increase padding for better touch targets */
  background-color: #C6F8DF; /* Button background color */
  color: #063D3A; /* Text color */
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Ensure text is legible */
  font-weight: 800; /* Adjust font weight if needed */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  width: 100%; /* Full width */
}

.login-container button:hover {
  background-color: #91F1CB; /* Change background color on hover */
}

/* Error message styles */
.error-message {
  color: red; /* Red color for error messages */
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-page-container {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .logo-container {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .login-logo {
    width: 120px; /* Adjust logo size for smaller screens */
  }

  .login-container {
    width: 100%;
    max-width: none; /* Remove max-width restriction on small screens */
  }

  .login-container form input,
  .login-container button {
    padding: 12px; /* Increase padding for better touch targets on smaller screens */
  }
}