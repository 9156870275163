@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.dashboard-container {
  display: flex;
  height: 100vh;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  background-color: #005057;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  margin-bottom: 20px;
}

.sidebar-link {
  color: #C6F8DF;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #00796A;
}

.icon {
  margin-right: 10px;
  font-size: 20px;
}

/* Main content styles */
.dashboard-content {
  flex-grow: 1;
  background-color: #F5F5F5;
  padding: 40px;
  overflow-y: auto;
}

.dashboard-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

.dashboard-content p {
  font-size: 18px;
  color: #666;
}
